@tailwind base;
@tailwind components;
@tailwind utilities;

input:focus {
  outline: none !important;
}

/* Add this to your CSS file */
.thick-strikethrough {
  text-decoration: line-through;
  text-decoration-thickness: 2px; /* Adjust the thickness as needed */
  text-decoration-color: red; /* Set the color of the strikethrough line */
}

@font-face {
  font-family: "Poppins";
  src: url("/public/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Poppins", sans-serif;
}
